<template>
  <vx-card class="contained-example-container">
    <div class="header">
      <div class="row">
        <div>
          <h4>{{ title }}</h4>
        </div>
        <div>
          <div class="actions">
            <span class="mr-2" @click="download">
              <vs-icon
                v-tooltip="lang.metrics.download[languageSelected]"
                icon="icon-download"
                icon-pack="feather"
                style="cursor: pointer"
              ></vs-icon>
            </span>
            <vs-dropdown class="mr-2">
              <a class="flex items-center text-grey">
                {{ lang.metrics.columns.days[languageSelected] }}
                <i class="material-icons">expand_more</i>
              </a>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="index in weekDays"
                  :key="index"
                  @click="updateWeekDay(allweekDays[index].id)"
                  >{{ allweekDays[index].day }}</vs-dropdown-item
                >
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>
      </div>
      <div>
        <h6 class="description">{{ description }}</h6>
      </div>
    </div>

    <div
      class="p-6 pb-base vs-con-loading__container"
      :id="`div-with-loading-${report._id}`"
    >
      <vue-apex-charts
        :key="key"
        type="bar"
        height="350"
        :options="options"
        :series="series"
      ></vue-apex-charts>
    </div>
  </vx-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapState, mapActions } from 'vuex'
const moment = require('moment')
const Json2csvParser = require('json2csv').Parser

export default {
  name: 'ColumnsV1',
  props: {
    report: {
      type: Object,
      required: true
    },
    type: {
      type: Array
    },
    platforms: {
      type: Array
    },
    services: {
      type: Array,
      required: true
    },
    interval: Object,
    versions: {
      type: Array,
      required: true
    }
  },
  components: {
    VueApexCharts
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    title() {
      const found = this.report.name.find(element => {
        return element.lang === this.languageSelected
      })
      if (found) {
        return found.text
      }
      return 'N/A'
    },
    description() {
      const found = this.report.description.find(element => {
        return element.lang === this.languageSelected
      })
      if (found) {
        return found.text
      }
      return 'N/A'
    }
  },
  data() {
    return {
      LIMIT: 10, // cantidad de elementos a mostrar
      key: new Date().getTime(),
      weekDays: [],
      info: [],
      allweekDays: [],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
              }
            }
          }
        ],
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        xaxis: {
          type: 'category',
          categories: [],
          trim: true,
          title: {
            text: 'Hours'
          }
        },
        yaxis: {
          title: {
            text: 'AVG Takeovers'
          }
        },
        legend: {
          position: 'right',
          offsetY: 40
        },
        fill: {
          opacity: 1
        }
      },
      // interval: {},
      series: [
        {
          name: 'AVG Takeovers',
          data: []
        }
      ]
    }
  },
  watch: {
    services() {
      this.load(this.report)
    },
    interval() {
      this.load(this.report)
    },
    platforms() {
      this.load(this.report)
    },
    versions() {
      this.load(this.report)
    }
  },
  methods: {
    ...mapActions('metrics', ['getReportData']),
    download() {
      function saveData(blob, fileName) {
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(url)
      }
      // eslint-disable-next-line no-unused-vars
      const total = this.series.reduce((acc, elem) => acc + elem, 0)

      let fields = ['Hour', 'AVG Takeover']
      let data = []
      this.options.xaxis.categories.forEach((c, index) => {
        data.push({
          Hour: c,
          'AVG Takeover': this.series[0].data[index]
        })
      })

      const json2csvParser = new Json2csvParser({ fields })
      const csv = json2csvParser.parse(data)

      let filename = `konecta_metric_${this.title}_`
      filename += new Date().toISOString()

      var BOM = '\uFEFF'
      var csvData = BOM + csv

      var blob = new Blob([csvData], {
        type: 'text/plain; encoding=utf-8'
      })

      saveData(blob, `${filename}.csv`)
    },
    updateWeekDay(index) {
      this.series[0].data = []
      if (this.weekDays.length > 0) {
        this.info.forEach(e => {
          if (e.weekday == this.weekDays[index]) {
            this.series[0].data.push(e.avg)
          }
        })
      }
    },
    async load(report) {
      this.allweekDays = [
        {
          day: this.lang.metrics.columns.monday[this.languageSelected],
          id: 1
        },
        {
          day: this.lang.metrics.columns.tuesday[this.languageSelected],
          id: 2
        },
        {
          day: this.lang.metrics.columns.wednesday[this.languageSelected],
          id: 3
        },
        {
          day: this.lang.metrics.columns.thursday[this.languageSelected],
          id: 4
        },
        {
          day: this.lang.metrics.columns.friday[this.languageSelected],
          id: 5
        },
        {
          day: this.lang.metrics.columns.saturday[this.languageSelected],
          id: 6
        },
        {
          day: this.lang.metrics.columns.sunday[this.languageSelected],
          id: 0
        }
      ]
      if (!this.services || this.services.length === 0) {
        return
      }
      if (!this.versions || this.versions.length === 0) {
        return
      }
      const toDateStr =
        this.interval && moment(this.interval.to).format('YYYY-MM-DD HH:mm:ss')
      const fromDateStr =
        this.interval &&
        moment(this.interval.from).format('YYYY-MM-DD HH:mm:ss')
      if (!this.platforms || this.platforms.length === 0) {
        return
      }
      const data = {
        type: this.type,
        services: this.services,
        versions: this.versions,
        from: fromDateStr,
        to: toDateStr,
        platforms: this.platforms
      }
      this.$vs.loading({
        container: `#div-with-loading-${report._id}`,
        scale: 1.0
      })
      try {
        const result = await this.getReportData({
          reportId: report._id,
          payload: data
        })
        let arr = result.data[0]
        this.info = arr
        this.series[0].data = []
        this.options.xaxis.title.text = this.lang.metrics.hours[
          this.languageSelected
        ]
        this.options.xaxis.categories = []
        this.weekDays = []
        arr.forEach(e => {
          if (!this.weekDays.includes(e.weekday)) {
            this.weekDays.push(e.weekday)
          }
        })

        if (this.weekDays.length > 0) {
          arr.forEach(e => {
            if (e.weekday == this.weekDays[0]) {
              this.series[0].data.push(e.avg)
              this.options.xaxis.categories.push(e.hour)
            }
          })
        }
        // this.series = []
        // this.options.labels = []
        this.key = new Date().getTime() // para actualizar el componente
        this.$vs.loading.close(
          `#div-with-loading-${report._id} > .con-vs-loading`
        )
      } catch (ex) {
        if (ex.message !== 'cancelled') {
          this.$vs.loading.close(
            `#div-with-loading-${report._id} > .con-vs-loading`
          )
          const reportName =
            this.languageSelected === 'es'
              ? report.name[0].text
              : report.name[1].text
          this.$vs.notify({
            text:
              this.lang.metrics.reportMessages.text[this.languageSelected] +
              `"${reportName}"`,
            color: 'danger'
          })
          console.error(ex)
        }
      }
    }
  },
  mounted() {
    this.load(this.report)
  }
}
</script>

<style lang="scss">
.custom-select {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 160px;

  label {
    padding-left: 5px;
    font-size: 0.85rem;
    color: rgba(0, 0, 0, 0.6);
  }

  select {
    padding: 5px;
    font-size: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding-right: 20px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.2s ease;
    height: 38.5px;
    width: auto;
    margin-top: 5px;
    background-color: white;
    color: #626262;
  }
}
</style>
